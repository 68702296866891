import React, { useEffect } from "react";
import { Menu } from "antd";
import { BrowserRouter as Router, NavLink, useLocation } from "react-router-dom";
import { HomeOutlined, TeamOutlined, ClusterOutlined, SearchOutlined, BarChartOutlined } from "@ant-design/icons";

const SideMenu = ({ authenticated }) => {
  const location = useLocation();

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["/"]}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/">
        <HomeOutlined />
        <span>Home</span>
        <NavLink to="/" />
      </Menu.Item>
      <Menu.Item key="/patients" disabled={!authenticated}>
        <TeamOutlined />
        <span>Patient List</span>
        <NavLink to="/patients" />
      </Menu.Item>
      <Menu.Item key="/devices" disabled={!authenticated}>
        <ClusterOutlined />
        <span>Devices</span>
        <NavLink to="/devices" />
      </Menu.Item>
      <Menu.Item key="/search" disabled={!authenticated}>
        <SearchOutlined />
        <span>Search</span>
        <NavLink to="/search" />
      </Menu.Item>
      <Menu.Item key="/statistics">
        <BarChartOutlined />
        <span>Statistics</span>
        <NavLink to="/statistics" />
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
