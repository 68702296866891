import React, { useState } from "react";
import "./App.css";
import "antd/dist/antd.css";
import {DevicePage} from "./routes/devices";
import NotFoundPage from "./routes/NotFoundPage";
import HomePage from "./routes/home";
import SideMenu from "./components/SideMenu";
//import MobileTabBar from "./components/MobileTabBar";

import logo from "./img/fhir-logo-long.png";
import icon from "./img/icon.png";
import profile from "./img/profile.png";

import {CaretDownOutlined, UnorderedListOutlined, AppstoreOutlined} from "@ant-design/icons";

import {TransitionGroup, CSSTransition} from "react-transition-group";
import {BrowserRouter as Router, Switch, Route, Link, useLocation} from "react-router-dom";

import {Layout, Avatar, Popconfirm, Radio, Tooltip, Modal, message} from "antd";
import GlobalContextConsumer from "./components/GlobalContext";

import {GlobalContext, GlobalContextProvider} from "./components/GlobalContext";

import FHIRSearchPage from "./routes/search";
import StatsPage from "./routes/statistics";
import {PatientListPage} from "./routes/patients";
import {useSession} from "./javascript/api";
import AccountCorner from "./components/AccountCorner";
import TestPage from "./routes/test";


const {Header, Sider, Footer} = Layout;


const DesktopMenu = ({ authenticated }) => {
  const [collapsed, setCollapsed] = useState(false);

  const updateCollapsed = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Sider
      collapsible
      onCollapse={updateCollapsed}
      breakpoint="lg"
      width="230"
      style={{
        boxShadow: "7px 0px 20px -10px rgba(0,0,0,0.35)",
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0
      }}
    >
      <Link to="/">
        <div
          className="logo"
          style={{
            background: `url("${collapsed ? icon : logo}") no-repeat`
          }}
        ></div>
      </Link>
      <SideMenu authenticated={authenticated} />
      <div style={{ flexGrow: 1 }}></div>
    </Sider>
  );
};


const App = () => {

  const [auth,setAuth] = useState(false);
  useSession(auth, setAuth);
  const {isMobile, setViewInCard} = React.useContext(GlobalContext);
  
  const routes = [
    {
      path: "/",
      exact: true,
      title: () => "FHIR Home",
      main: () => <HomePage auth={auth?.introspection?.active == true}/>
    },
    {
      path: "/devices",
      title: () => "Devices",
      main: () => <DevicePage/>
    },
    {
      path: "/search",
      title: () => "Search",
      main: () => <FHIRSearchPage/>
    },
    {
      path: "/patients",
      title: () => "Patients",
      main: () => <PatientListPage/>
    },
    {
      path: "/statistics",
      title: () => "Statistics",
      main: () => <StatsPage />
    },
    {
      path: "/stats",
      title: () => "stats-test",
      main: () =>  <StatsPage/>
    },
    {
      path: "/test",
      title: () => "test",
      main: () =>  <TestPage/>
    },
    {
      title: () => "404 Not Found",
      main: () => <NotFoundPage/>
    }

  ];


  const RouterContent = () => {
    let location = useLocation();
    return (
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="page" timeout={300}>
            <Switch location={location}>
              {routes.map((route, index) => (
                  <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={
                        <div className="container">
                          <div className="page">
                            <route.main/>
                            <FhirFooter></FhirFooter>
                          </div>
                        </div>
                      }
                  />
              ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
    );
  };


  const basename =
      window.location.hostname === "henryz00.github.io"
          ? "GOSH-FHIRworks2020-React-Dashboard/#/"
          : "";

  return (
      <Router basename={basename}>
        <Layout style={{minHeight: 100 + "vh"}}>
          <GlobalContextConsumer>
            {value => {
              if (!value.isMobile) {
                return <DesktopMenu authenticated = {auth?.introspection?.active == true} ></DesktopMenu>;
              } else {
                return;
              }
            }}
          </GlobalContextConsumer>

          <Layout className="site-layout">
            <Header className="site-layout-header">
              <h2 style={{paddingLeft: 20 + "px"}}>
                <Switch>
                  {routes.map((route, index) => (
                      <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          children={<route.title/>}
                      />
                  ))}
                </Switch>
              </h2>

              <div style={{flexGrow: 1, textAlign: "right", paddingRight: "20px"}}>
                <GlobalContextProvider>
                  <Radio.Group
                      defaultValue="table"
                      onChange={e => {
                        console.log(e);
                        this.handleViewChange(e.target.value);
                      }}
                      size="small"
                  >
                    <Radio.Button value="table">
                      <Tooltip placement="bottom" title="View patients in a table">
                        <UnorderedListOutlined/>
                      </Tooltip>
                    </Radio.Button>
                    <Radio.Button value="card">
                      <Tooltip placement="bottom" title="View patients in cards">
                        <AppstoreOutlined/>
                      </Tooltip>
                    </Radio.Button>
                  </Radio.Group>
                </GlobalContextProvider>
                <AccountCorner auth={auth}/>
              </div>
            </Header>
            <RouterContent></RouterContent>
          </Layout>
        </Layout>
      </Router>
  );
}


const FhirFooter = () => {
  return (
      <Footer style={{textAlign: "center"}} className="footer">
        FHIR Dashboard ©2020 Created by <a href="https://github.com/henryz00">henryz00</a>
        <div style={{opacity: 0.5}}>
          Using{" "}
          <a href="https://www.hl7.org/fhir/" style={{color: "black"}}>
            HL7 FHIR
          </a>{" "}
          Standards,{" "}
          <a href="https://cs.ucl.ac.uk" style={{color: "black"}}>
            UCL CS
          </a>{" "}
          <a
              href="https://github.com/henryz00/GOSH-FHIRworks2020-React-Dashboard"
              style={{color: "black"}}
          >
            COMP0016 Project
          </a>{" "}
          for GOSH DRIVE and NHS England
          <div>
          </div>
        </div>
      </Footer>
  );
};

export default App;
