import React, {useState} from "react";
import {requestFromServer} from "../javascript/api";
import TableList from "../components/TableList";


const deviceColumns = [
  {
    title: 'Id',
    dataIndex: ["resource","id"],
    //render: (entry) => entry ? entry : null,
  },
  {
    title: 'Device name',
    dataIndex: ["resource",'deviceName'],
    render: (list) => list ? list[0]?.name : null,
  },
  {
    title: 'identifier',
    dataIndex: ["resource",'identifier'],
    render: (obj) => obj ? obj[0]?.value : null,
  },
];

function DevicePage() {

  //Load first batch of data on site load or from localStorage
  /*const device_bundle = useData("fhir/Device","_count=" + pageSize.toString() ,"Devices");
  if(device_bundle) {
    devices = bundleToList(device_bundle); // // Extract all the entries into an array of resources
  }
  else {
    devices = null;
  }
  */

  const [total,setTotal] = useState(0);

  //Get the Total amount of patient resources
  requestFromServer("fhir/Device","_count=0")
      .then(
          (res_json) => {
            setTotal(res_json.total);
          }
      )

  return <DeviceListDisplay total={total}/>;
}

function DeviceListDisplay({total,route,param}) {



  return <TableList
      columns={deviceColumns}
      total={total}
      route={ route? route : "fhir/Device"}
      param={param}
      />
}

export {
  DevicePage,
  DeviceListDisplay,
  deviceColumns
}