import React, {useState, useEffect, createContext} from "react";

export const GlobalContext = createContext({ isMobile: false });

export const GlobalContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [viewInCard, setViewInCard] = useState(false);

  const updateWindowDimensions = () => {
    const isMobile = window.innerWidth < 620;
    setIsMobile(isMobile);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return (
      <GlobalContext.Provider value={{ isMobile, viewInCard, setViewInCard }}>
        {children}
      </GlobalContext.Provider>
  );
};

const GlobalContextConsumer = GlobalContext.Consumer;
export default GlobalContextConsumer;

//ref https://juejin.im/post/5c87c1b4f265da2dc453b6d6

/* OLD LEGACY APPROACH with classes, nowadays functional components and hooks are used

export const GlobalContext = React.createContext({ isMobile: false });


export class GlobalContextProvider extends React.Component {
  state = {
    isMobile: false,
    viewInCard: false
  };

  setViewInCard = viewInCard => {
    this.setState({ ...this.state, viewInCard: viewInCard });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const isMobile = window.innerWidth < 620;
    this.setState({ width: window.innerWidth, height: window.innerHeight, isMobile });
  }

  render() {
    const { setViewInCard } = this;
    return (
      <GlobalContext.Provider value={{ ...this.state, setViewInCard }}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

const GlobalContextConsumer = GlobalContext.Consumer;
export default GlobalContextConsumer;
*/