import React, {useRef, useState} from "react";
import {Drawer, Result, Table} from "antd";
import ReactJson from "react-json-view";
import {useData} from "../javascript/api";

/*
* Returns a Table and Drawer for viewing
* @param {List} data List of FHIR Resources for display
* @param {list} columns List of columns with information about which components of the data will be displayed
 */
export default function TableList({columns,route,param = null}) {

  let pageSize = 10; // Size of viewable table entries on one page
  const fetchAmount = 100; // Amount of resources requested at once; May need to be set according to the FHIR Server!

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // State for showing a loading indicator
  const [response,setResponse] = useState(null);

  // States for the Raw JSON view
  const [showDrawer,setShowDrawer] = useState(false);
  const [rawResource,setRawResource] = useState(null);
  
  const bundle = response && response.json ? response.json : null;


  // using UseRef for persistence, without re-rendering on change. Changing the currentFetchOffset shouldn't cause a rerender because it indirectly causes it by changing the tableContent
  let currentFetchOffset = useRef(0);

  //Fetch data according to the offset and the fetchAmount
  useData(setResponse, setLoading, route,((param? param+"&" : "") + "_count=" + fetchAmount + "&_offset=" + currentFetchOffset.current));

  console.log({bundle});
  console.log({response});

  if(response && response.text) {
    return <Result  status="error"
                    title="Server returned non JSON data"
                    subTitle="Please check the results below.">
                    <p>{response.text}</p>
    </Result>
  }

  if(response && response.json && response.json.message) {
    return <Result  status="warning"
    title="Server retuned error message"
    subTitle="Please check the results below.">
    <p>{response.json.message}</p>
</Result>
  }
  
  //add column with the raw view button
  if(!(columns.some(column => column.title === "Raw view" ))) { // Check if the "Raw view" field already exists
      columns = columns.concat({
        title: 'Raw view', dataIndex: '', key: 'raw',
        render: (obj) => obj? ( // Check if null, if there is nothing to display, the columns stays empty
            <a
                onClick={() => {
                  setShowDrawer(true);
                  setRawResource(obj);
                }}
            >
              View raw
            </a>) : null
      });
    }

  return (
      <div>
        <Table
            dataSource={bundle ? Array(currentFetchOffset.current ).concat(bundle.entry) : null}
            columns={columns}
            rowKey={"id"}
            loading={loading}
            pagination={{
              pageSize:pageSize,
              total: bundle ? bundle.total : 0,
              current: currentPage,
              onChange: (page,pageSize) => {
                setCurrentPage(page); //update page state for the pagechange
                //calculate the offset
                let calculatedFetchOffset = Math.floor((page-1) /(fetchAmount/pageSize))*fetchAmount;
                //if the offset changes, update the stored offset and view the loading status (the fetch happens during rerender)
                if(calculatedFetchOffset < currentFetchOffset.current || calculatedFetchOffset > currentFetchOffset.current) {
                  currentFetchOffset.current = calculatedFetchOffset;
                  setLoading(true);
                     }
                  }
              }}
            footer={() =>{
              return "Total: "+ (bundle && bundle.total ? bundle.total : 0);
            } }
        />
        <Drawer
            title={"Resource View"}
            placement={"right"}
            closeable={true}
            onClose={() => {
              setShowDrawer(false);
              setRawResource(null);
            }}
            open={showDrawer}
            width={"40%"}
        >
          <ReactJson src={rawResource}/>
        </Drawer>
      </div>
  )
};

