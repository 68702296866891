import React, {useState} from "react";

import {requestFromServer} from "../javascript/api";
import ReactJson from "react-json-view";
import TableList from "../components/TableList";
import {Drawer, Card, Button, Descriptions} from "antd";

function PatientListPage() {
  const [total,setTotal] = useState(0);

  //Get the Total amount of patient resources
  requestFromServer("fhir/Patient","_count=0")
      .then(
          (res_json) => {
            setTotal(res_json.total);
          }
      )

  return <PatientListDisplay total={total} localKey={"Patients"}/>;
}

function PatientListDisplay({total,param, localKey=null, route=null}) {

  //Sates for the Observation Drawer
  const [observations, setObservations] = useState(null); //State for holding the observations shown in the drawer
  const [showObservationsDrawer, setShowObservationsDrawer] = useState(false);
  const [currentPatientId,setCurrentPatientId] = useState(null); // ID for patient whose observations are currently shown

  //States for the raw JSON view for the observations, inside the observation Drawer
  const [showRawObservationsDrawer, setShowRawObservationsDrawer] = useState(false)
  const [currentRawObservation,setCurrentRawObservation] = useState(null);

  function fetchObservationsForPatient(id) { // Helper function for saving the result of the fetch into a state variable
    requestFromServer("fhir/getObservationsForPatient/" + id,).then(
        (res) => {
          setObservations(res);
        }
    )
  }

  // Columns for the Patient list table
  const patientColumns = [
    {
      title: 'Name (Family, Given)',
      dataIndex: ['resource','name'],
      render: (obj) => obj ? obj[0]?.family + ", " + obj[0]?.given : null,
    },
    {
      title: 'Id',
      dataIndex: ['resource','id'],
    },
    {
      title: 'Observations',
      dataIndex: 'resource',
      render: (obj) => obj ? ( //Open the drawer and request the observations for the patient
          <a onClick={() => {
            setCurrentPatientId(obj.id);
            setShowObservationsDrawer(true);
            fetchObservationsForPatient(obj.id);
          }}>
            view
          </a>) : null,
    },
    {
      title: 'Gender',
      dataIndex: ['resource','gender'],
    },
    {
      title: 'Birth Date',
      dataIndex: ['resource','birthdate'],
    },
    {
      title: "Country",
      dataIndex: ['resource',"country"],
    }
  ];

  return (
      <div>
        <TableList
            columns={patientColumns}
            route={ route ? route: "fhir/Patient"}
            localKey={localKey}
            param={param}
            total={total}
        />
        <Drawer
            title={"Observations for patient " + currentPatientId}
            open={showObservationsDrawer}
            setOpen={setShowObservationsDrawer}
            setResource={setObservations}
            closeable={true}
            onClose={() => {
              setShowObservationsDrawer(false);
              setCurrentPatientId(null);
              setObservations(null)
            }}
            width={"65%"}>
          {observations && observations.entry ? (
              observations.entry.map( // Add a card for each entry inside the bundle
                  entry => (

                      <Card title={"Observation " + entry.resource?.id}>
                        <Descriptions bordered={true} layout="vertical">
                          <Descriptions.Item label="coding System" >{entry.resource?.code?.coding[0]?.system}</Descriptions.Item>
                          <Descriptions.Item label="code">{entry.resource?.code?.coding[0]?.code}</Descriptions.Item>
                          <Descriptions.Item label="Issued">{entry.resource?.issued}</Descriptions.Item>
                          <Descriptions.Item label="Status">{entry.resource.status}</Descriptions.Item>
                        </Descriptions>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                          <Button onClick={ () => {
                            setShowRawObservationsDrawer(true);
                            setCurrentRawObservation(entry.resource)
                          }}>
                            Raw View
                          </Button>
                        </div>

                      </Card>
                  )
              )
          ) : <p>No observations or error loading them!</p>}
          <Drawer title={"Raw Observation View"}
                  open={showRawObservationsDrawer}
                  closeable={true}
                  onClose={() => {
                    setShowRawObservationsDrawer(false);
                    setCurrentRawObservation(null)
                  }}
          >
            <ReactJson src={currentRawObservation}></ReactJson>
          </Drawer>
        </Drawer>

      </div>
  )
}

export {
  PatientListPage,
  PatientListDisplay
}
