import React, {useEffect, useState} from "react";
import {Bar, Pie} from "react-chartjs-2";
import {requestFromServer} from "../javascript/api";
import {Card, Col, Row, Spin,Table, Descriptions} from "antd";


export default function StatsPage() {

  const [statData, setStatData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    let ignore = false;
    requestFromServer("statistics","recalculate=true").then(result => {
      if (!ignore) {
        setStatData(result.json);
        setLoading(false);
      }
    }).catch((error) => {
      console.error('Error fetching the URL:', error);
      setLoading(false);
    });
    
    return () => {
      ignore = true;
    };
  }, []);


  if (loading) {
    // Show a loading spinner while loading
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <Spin size="large" tip="Loading statistics..." />
      </div>
    );
  }

  if (!statData) {
    return <p style={{ textAlign: "center" }}>No data available</p>;
  }

  // If the data is available draw the plots
  if(statData) {

    const { totalResources, lastCalculationDateTime } = statData;
    const resourceCountLabels = Object.keys(statData.resourceCount);
    const resourceCountAmounts = Object.values(statData.resourceCount);

    const resourceCountColumns =  [{
      title: 'Resouce',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount
    },]

    const resourceCountData = resourceCountLabels.map((label, index) => ({
      key: index, // Unique key for each row
      name:label,
      amount: resourceCountAmounts[index],
    }));
    
    const top10Labels = Object.keys(statData.top10resources);
    const top10Data = {
      labels: top10Labels,
      datasets: [
        {
          label: 'Amount of this resource on the server',
          data: Object.values(statData.top10resources),
          backgroundColor: [
            "#FF6384", "#36A2EB",
            "#FFCE56","#4BC0C0", "#9966FF", "#FF9F40", "#DC3912", "#109618", "#FF9900", "#990099", "#0099C6"],
        }
      ]
    };

    console.log({resourceData: resourceCountData})
    //<Col span={12}><Card title={"Amounts of each available resource"}> <Bar data={resourceData}></Bar> </Card></Col>
    return (
      <>
      <Row>
        {/* Descriptions Section */}
      <Col span={24}>
        <Descriptions bordered>
          <Descriptions.Item label="Total Amount of Resources">
            {totalResources || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Date Time of Last Calculation">
            {lastCalculationDateTime || "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      </Row>

      <Row>
        <Col span={12}><Card title={"FILLER"}> </Card></Col>
        <Col span={12}><Card title={"Top 10 most common resources"}><Pie data={top10Data}></Pie> </Card></Col>
      </Row>
      <Row style={{overflow: "hidden"}}>
        <Col span={12} style={{ height: "100%", overflow: "auto" }}>
          <Card> 
            <Table columns={resourceCountColumns} dataSource={resourceCountData} pagination={false} scroll={{ y: 300 }} size={"small"}/>
          </Card>
        </Col>
      </Row>
      </>
       
    )
  }
  return null;
}
