import React, {useState} from "react";
import {AutoComplete, Button, Card, Col, Form, Row} from "antd";
import TextArea from "antd/es/input/TextArea";

import Header from "../components/Header";
import {requestFromServer} from "../javascript/api";
import {resourceTypes,ResourceFormSelector} from "../components/searchFormContent";
import  {DeviceListDisplay} from "./devices";
import {PatientListDisplay} from "./patients";


export default function FHIRSearchPage({token}) {

  const [resultType,setResultType] = useState("");
  const [fetchRoute,setFetchRoute] = useState(null);
  const [total,setTotal] = useState(0);

  if(fetchRoute && resultType) {
      requestFromServer("fhir/" + resultType,"_count=0")
          .then(
              (res_json) => {
                setTotal(res_json.total);
              }
          );
  }

  console.log({fetchRoute})

  // Check the result resourceType for selecting the appropriate table columns
  let table = null;
  switch(resultType){
    case "Patient":
      table = <PatientListDisplay token={token} route={"fhir/" + resultType} param={fetchRoute} total={total} localKey={null}/>;
      break;
    case "Device":
      table = <DeviceListDisplay token={token} route={"fhir/" + resultType} param={fetchRoute} total={total} localKey={null}/>;
      break;
  }
  console.log("fhir/" + resultType + fetchRoute)

  return  <div>
    <Header title="Advanced FHIR Search"></Header>
    <FHIRSearchForm token={token} setType={setResultType} setRoute={setFetchRoute}></FHIRSearchForm>
    {table}
  </div>;
};

function FHIRSearchForm({setType,setRoute}) {

  const [form] = Form.useForm();
  const [searchResourceType,setSearchResourceType] = useState("");

  const onFinish = async (values) => {
    let queryString = "";

    //Build FHIR queryString form dynamic form content received in values parameter
    for (const key in values) {

      if (Object.hasOwnProperty.call(values, key)) { // Check if the property is the object own and not inherited
        if(key !== "resourceType" && values[key]) { // skip the mandatory resourceType, its handled separately
          if(queryString.length > 0) {
            queryString = queryString.concat(`&${key}=${encodeURIComponent(values[key])}`);
            console.log(`&${key}=${encodeURIComponent(values[key])}`)
          }
          else {
            queryString = queryString.concat(`${key}=${encodeURIComponent(values[key])}`);
            console.log(`${key}=${encodeURIComponent(values[key])}`)
          }
        }
      }
    }

    setRoute(queryString);
    setType(values.resourceType);
  };
  const searchForm = <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}>
    <Row>
      <Col span={6}>
        <Form.Item
            name="resourceType"
            label="ResourceType"
            rules={[
              {
                required: true,
                message: 'Please select an Resource Type!',
              },
              {
                validator(_, value) {
                  if (!resourceTypes.some(item => item.value === value)) { //check if the input is matching something in the resourceTypes array
                    return Promise.reject('Please select a predefined Resource!');
                  }
                  return Promise.resolve();
                },
              },
            ]}
        >
          <AutoComplete
              style={{width: "200px"}}
              options={resourceTypes}
              placeholder="Select e.g `Device`"
              onChange={(input) => {
                if(resourceTypes.some(item => item.value === input) && searchResourceType !== input) { //Check if input matches any definitions, and is not already set
                  setSearchResourceType(input);
                }
          }}
              filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }/>
        </Form.Item>
      </Col>

      <Col flex="auto">
        <Form.Item  name="queryString"
                    label="Advanced query"
                    rules={[]}>

          <TextArea style={{width:"100%"}} placeholder="query string" allowClear />
        </Form.Item>
      </Col>

    </Row>
    <ResourceFormSelector resourceType={searchResourceType}/>

      <Button type="primary" htmlType="submit">
        Search
      </Button>
      <Button
          style={{
            marginLeft: 8
          }}
          onClick={() => {
            form.resetFields();
          }}
      >
        Clear
      </Button>
  </Form>

  return <Card>{searchForm}</Card>
}

