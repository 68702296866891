import {useEffect, useState} from "react";
import {User} from "oidc-client-ts";

// Request Parameters
const SERVER_URL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : "";
console.log({SERVER_URL})
const requestHeaders = new Headers({
  "authorization": ""
});


function useSession(auth, setAuth){

  const AUTH_SERVER_URL = "https://c107-055.cloud.gwdg.de/api/check_session"; // TODO REMOVE HARDCODED VALUE

  useEffect(() => {
    fetch(AUTH_SERVER_URL)
      .then((response) => response.json())
      .then((data) => {
        console.log({data})
        if(data !== auth) {
          setAuth(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching the URL:', error);
      });

  },[]);
}

//----------------------------------------------------------------------------------------------------------------------

/*
* Get the entries of a bundle as a list
* @param {Object} bundle FHIR-search bundle
* @return {List} Returns an list with the resources or null if no entries are present
 */
function bundleToList(bundle) {
  if (bundle && bundle.entry) {
    return bundle.entry.map(entry => entry.resource); // Extract all the entries into an array of resources
  } else {
    console.log("bundleTOLIST")
    console.log({bundle})
    return [];
  }
}

/*
* Route based fetch from the backend server
* @param {String} route The route that shall be requested.
* @param {String} token JWT token to be used for authentication with the backend server.
* @param {String} param Additional parameters, will be sent as the query_string of the request.
* @return {Promise} Returns a promise containing the JSON Formatted Bundle of Resources, returns null if the token is null.
*/
async function requestFromServer(route, param=null) {
  const fetchUrl = SERVER_URL + route + (param? "?" + param : "");
  console.log({fetchUrl});
  try {
    // Check if the content-type indicates JSON
    const response = await fetch(fetchUrl);
    const contentType = response.headers.get('content-type');
    
    if (contentType && contentType.includes('application/json')) {
      const jsonData = await response.json();
      return {"json":jsonData};  // Return JSON data
    } else if (contentType && contentType.includes('text/html')) {
      const htmlData = await response.text();
      return {"text":htmlData};  // Return HTML data
    } else {
      const textData = await response.text();
      return {"text":textData};  // Return plain text data
    }


  } catch (error) {
    console.error('Error:', error);
    throw error;  // Re-throwing the error to handle it ouside this function
  }
  /*
  return new Promise((resolve, reject) => {
    fetch(SERVER_URL + route + (param? "?" + param : ""), {headers: requestHeaders})
        .then( res => {
          console.log({res});
          if (!res.ok) {
            const e = new Error("Request failed with status:" + res.status)
            console.log(e);
            return e;
          }
          
          resolve(await res.json());
        })
        .catch(e => {
          console.log("Caugt error: \" " + e + "\" when requesting resources from " + SERVER_URL + route)
          return e;
        });
  }); */
}

/*
* Custom hook for synchronizing the page (on page load) with data that's requested form the backend server. 
* Loads data from localStorage if key is passed and data is available inside localStorage
* @param {Function} setBundle useState function for storing and setting the requested data
* @param {String} route The route shat shall be requested
* @param {String} token JWT token, as a string, to be used for authentication with the backend server.
* @param {Object} param Optional parameters, will be sent as the query_string of the request.
* @param {Object} key Optional localstorage key, allows for first trying to load data from localstorage if there is data
* @return {Object} Returns an object that contains the JSON formatted FHIR Bundle.
 */
function useData(setResponse, setLoading, route, param=null) {

  useEffect(() => {
    let ignore = false;
  
    if (route) {

      requestFromServer(route, param ? param + "&_total=accurate" : "?_total=accurate") // add total accurate to receive an accurate amount of matching resources, this is necessary for the paging
          .then(res => {
            console.log({res});
            if (!ignore) {
              setLoading(false);
              setResponse(res);
            }
          })
          .catch( e=>{
            setResponse(e)
            console.log("ERROR CATCHED")
            return e; //TODO proper error handling
          })
      return () => {
        ignore = true;
      };
    }
  }, [route, param]);

}

/*
* Clears resources stored in localStorage, used when logging out. Currently, clears the Devices and Patients
* @return null
 */
function clearLocalStorage() {
  localStorage.removeItem("Devices");
  localStorage.removeItem("DevicesPage");
  localStorage.removeItem("Patients");
  localStorage.removeItem("PatientsPage");
}

export {
  bundleToList,
  requestFromServer,
  useData,
  clearLocalStorage,
  useSession,
};
