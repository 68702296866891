import React, { useEffect,useState } from "react";

export default function TestPage() {

  const AUTH_SERVER_URL = "https://c107-055.cloud.gwdg.de/api/check_session";
  const [auth,setAuth] = useState(null);

  useEffect(() => {
    fetch(AUTH_SERVER_URL)
      .then((response) => response.json())
      .then((data) => {
        console.log({ data })
        if(data !== auth) {
          setAuth(data)
        }
      })
      .catch((error) => {
        console.error('Error fetching the URL:', error);
      });

  },[]);

console.log({auth});
  const handleRedirect = () => {
    // Redirect to the URL in state
    if(auth.redirect_url) {
      console.log(auth.redirect_url)
      window.location.href = auth.redirect_url;
    }
  };

  if(!auth) {
    return <div>Checking session cookie...</div>
  }

  return (
    <div>
     Authenticated? {auth.redirect_url ? (
        <button onClick={handleRedirect}>
          Unauthenticated, initiate auth flow
        </button>
      ) : (
        <p>Authenticated: </p>
      )}
    </div>
  );
};

