import {Form, Input} from "antd";
import React from "react";

const resourceTypes = [
  {
    value: 'Device',
  },
  {
    value: 'Patient',
  },
  {
    value: 'Observation',
  },
];

function ResourceFormSelector({resourceType}) {
  console.log(resourceType)
  switch (resourceType) {
    case 'Device':
      return <DeviceSearchForm/>;
    case 'Patient':
      return <PatientSearchForm/>;
    case 'Observation':
      return <ObservationSearchForm/>;
    default:
      return <GenericSearchForm/>;
  }
}

function DeviceSearchForm() {
  return (<Form.Item
      label="Device Name"
      name="device-name"
  >
    <Input />
  </Form.Item>);
}

function PatientSearchForm() {
  return (<Form.Item
      label="Phonetic Name search"
      name="phonetic"
  >
    <Input />
  </Form.Item>)

}

function ObservationSearchForm() {
  console.log("Observation")
  return null;
}

function GenericSearchForm() {

  return null;
}

export {
  resourceTypes,
  PatientSearchForm,
  ResourceFormSelector,
}