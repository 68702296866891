import React from "react";
import "./home.css";
import icon_user from "../img/icon_user.png";
import icon_search from "../img/icon_search.png";
import icon_stats from "../img/icon_stats.png";
import { ClusterOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import {Popover} from 'antd';
import { useHistory } from "react-router-dom";

function HomePage({auth}) {
  const history = useHistory();

  const content = "Please log in!";
  const title = "Not available while logged out!"



  return (
    <div>
      <Header title="FHIR Dashboard" isHome="true" />
      <div className="center_float">
          <Popover content={auth ? "List patient resources.\n Requires login." : content} title={auth ? "Patient Page": title} trigger="hover">
            <div
            className="menu_card"
            onClick={auth ? () => history.push("/patients") : null}>
            <img src={icon_user} alt="user" />
            <div className="text">Patients</div>
            </div>
        </Popover>
        <Popover content={auth ? "List device resources.\n Requires login." : content} title={auth ? "Device Page": title} trigger="hover">
          <div
            className="menu_card"
            onClick={auth ? () => history.push("/devices") : null}
          >
            <ClusterOutlined />
            <div className="text">Devices</div>
          </div>
        </Popover>
        <Popover content={auth ? "Search fhir resources.\n Requires login." : content} title={auth ? "Search Page": title} trigger="hover">
          <div
            className="menu_card"
            onClick={auth ? () => history.push("/search") : null}
          >
            <img src={icon_search} alt="search" />
            <div className="text">Search</div>
          </div>
        </Popover>
        <Popover content={"Display statistics about the fhir data."} title={"Statistics Page"} trigger="hover">
          <div
            className="menu_card"
            onClick={() => history.push("/statistics")}
          >
            <img src={icon_stats} alt="stats" />
            <div className="text">Statistics</div>
          </div>
        </Popover>
        
      </div>
    </div>
  );
};

export default HomePage;