// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/homebg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  background: rgb(71, 227, 255);
  height: 200px;
  text-align: center;
  line-height: 200px;
  font-size: 3em;
  color: white;
  background-image: linear-gradient(to right, rgba(0, 31, 97, 0.8), rgba(66, 238, 244, 0.5)),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  border-radius: 0;
  padding: 0 20px;
  -webkit-user-select: none;
          user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ;2CAC0B;EAC1B,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".header {\n  width: 100%;\n  background: rgb(71, 227, 255);\n  height: 200px;\n  text-align: center;\n  line-height: 200px;\n  font-size: 3em;\n  color: white;\n  background-image: linear-gradient(to right, rgba(0, 31, 97, 0.8), rgba(66, 238, 244, 0.5)),\n    url(\"../img/homebg.jpg\");\n  background-size: cover;\n  border-radius: 0;\n  padding: 0 20px;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
